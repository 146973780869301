<template>
  <v-snackbar
    v-for="(snackbar, index) in snackbars"
    :key="snackbar.key"
    :timeout="snackbar.timeout"
    :color="snackbar.type"
    :title="snackbar.title"
    position="sticky"
    :model-value="true"
    :style="{ bottom: `${(index) * 90}px` }"
    @update:model-value="() => close(snackbar.key!)"
  >
    <v-list-item
      class="pa-0"
      :title="snackbar.title"
      :subtitle="snackbar.text"
      :prepend-icon="
        snackbar.type === 'success'
          ? 'check_circle'
          : snackbar.type === 'error'
            ? 'error'
            : snackbar.type === 'warning'
              ? 'warning'
              : 'info'
      "
    >
    </v-list-item>

    <v-progress-linear v-if="snackbar.pending" class="position-absolute" indeterminate></v-progress-linear>

    <template #actions>
      <v-btn v-if="snackbar.closable" @click="() => close(snackbar.key!)">
        {{ $t("close") }}
      </v-btn>
      <v-btn v-for="(action, index) in snackbar.actions" :key="index" @click="action.handler">
        {{ action.label }}
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script setup lang="ts">
const props = defineProps<{ snackbars: Array<Snackbar> }>()
const { snackbars } = toRefs(props)

const close = (key: string) => {
  const index = snackbars.value.findIndex((snackbar) => snackbar.key === key)
  snackbars.value.splice(index, 1)
}
</script>